<template>
  <div class="ma-4">
    <v-form @submit.prevent="send" class="d-flex">
      <v-text-field
        autofocus
        rounded
        filled 
        class="noline general-custom-field poppins f13 secondary-1--text fw500" 
        dense
        placeholder="Type a message..."
        
        hide-details
        v-model="message"
      />
      <!-- append-icon="mdi-emoticon" -->
      <v-btn type="submit" color="primary" fab elevation="0" small class="ml-2" :disabled="message == ''">
        <v-icon>
          mdi-arrow-right
        </v-icon>
      </v-btn>
    </v-form>
  </div>
</template>

<script>
export default {
  data: () => ({
    message: ''
  }),
  methods: {
    send() {
      this.$emit('send', {message: this.message})
      this.message=''
    }
  }
}
</script>