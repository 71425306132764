<template>
    <v-dialog persistent max-width="500" v-model="dialog" >
      <v-sheet class="pa-4" rounded="lg">
        <div class="d-flex align-center">
          <div class="poppins fw500">
            Contacts
          </div>
          <v-spacer/>
          <v-btn icon small @click="$emit('close')">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>
        <v-row no-gutters class="my-2">
          <v-col cols="12" lg="8" md="8" sm="12">
            <v-text-field 
              filled 
              class="noline general-custom-field roboto f14 secondary-1--text fw500" 
              dense
              prepend-inner-icon="mdi-magnify"
              placeholder="Search"
              hide-details
              v-model="search"
            />
          </v-col>
          <v-col cols="4" class="hidden-sm-and-down">
            <div class="d-flex align-center justify-end">
              <div class="f12 poppins mr-1 secondary-1--text">Filter by:</div>
              <v-select 
                filled 
                class="col-7 noline general-custom-field roboto f14 secondary-1--text fw500" 
                dense
                hide-details
                :items="items"
                v-model="filter"
              />
            </div>
          </v-col>
        </v-row>
        <v-sheet height="300" class="overflow-y-auto">
          <a href="#" 
            class="text-decoration-none" 
            v-for="item in contactList" 
            :key="item.id"
            @click="$emit('startMessage', item)"
          >
            <v-sheet class="my-3 d-flex align-center" >
              <v-avatar class="" size="50">
                <v-img 
                :src="item.image?item.image.url:require('../../../assets/default-photo.png')"/>
              </v-avatar>
              <div class="poppins ml-3">
                <div class="f13 fw500">
                  {{`${item.first_name} ${item.last_name}`}} 
                </div>
                <div class="f12 secondary--text">
                  {{`${item.role}`}} 
                </div>
              </div>
            </v-sheet>
          </a>
        </v-sheet>
      </v-sheet>
    </v-dialog>
  </template>
  
  <script>
  export default {
    props: ['dialog','contacts'],
    data: () => ({
      items: ['ALL', 'USER', 'INSTRUCTOR'],
      filter: 'ALL',
      search: '',
      asset_path: `${process.env.VUE_APP_ASSET_PATH}`
    }),
  
    computed: {
      contactList(){
        if (this.search == '') {
          if (this.filter == 'ALL' || this.$vuetify.breakpoint.mobile){
            return this.contacts
          } else {
            return this.contacts.filter(contact => contact.role == this.filter)
          }
        } else {
          if (this.filter == 'ALL' || this.$vuetify.breakpoint.mobile){
            return this.contacts.filter(contact => String(`${contact.first_name} ${contact.last_name}`).toLowerCase().search(this.search.toLowerCase()) !== -1)
          } else return this.contacts.filter(contact => contact.role == this.filter && String(`${contact.first_name} ${contact.last_name}`).toLowerCase().search(this.search.toLowerCase()) !== -1)
        }
        
      }
    }
  }
  </script>