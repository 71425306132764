<template>
  <v-sheet color="#FAFAFA" rounded="" class="my-2">
    <div class="d-flex align-center pa-1 a">
      <div class="">
        <v-avatar>
          <v-img 
            :src="item.image?item.image.url:require('../../../assets/default-photo-group.png')"
            v-on:error="require('../../../assets/default-photo-group.png')"/>
        </v-avatar>
      </div>
      <div class="ml-2 flex-grow-1">
        <div class="d-flex align-center justify-space-between">
          <v-sheet color="transparent" class="overflow-x-hidden title-ellipse poppins f12 fw500" max-width="100">
            {{`${item.name}`}}
          </v-sheet>
          <div class=" poppins f10 secondary--text" v-if="item.latest_group_message">
            {{$dateFormat.fromNow(item.latest_group_message.created_at)}}
          </div>
        </div>
        <v-sheet color="transparent" class="overflow-x-hidden poppins f10 secondary--text title-ellipse" max-width="200" v-if="item.latest_group_message">
          {{item.latest_group_message.message_body}}
        </v-sheet>
      </div>
    </div>
  </v-sheet>
</template>

<script>
export default {
  props: ['item', 'user'],
  data: () => ({
    asset_path: `${process.env.VUE_APP_ASSET_PATH}`,
  })
}
</script>

<style>

</style>